@import 'styles/colors';

.container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  color: white;
  z-index: 999;
}

.navigation {
  height: 60px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  background-color: $dark-purple-3;
  color: white;
}

.linkList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.link {
  display: block;
  margin: 12px 0;

  * {
    text-decoration: none;
    color: inherit;
  }
}

.languageSelectorContainer {
  position: relative;
  text-transform: uppercase;

  .languageSelectorItem {
    cursor: pointer;
    padding: 12px 8px;
    color: white;
    margin: 0 auto;
    width: max-content;
  }

  .languageSelector {
    background-color: $dark-purple-3;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.verticalSeparator {
  width: 1px;
  height: 24px;
  background-color: white;
  margin: 0;
}

.menuIcon {
  display: none;
}

.menu {
  position: absolute;
  right: 0;
  left: 0;
  background-color: $dark-purple-3;
  color: white;
  text-align: center;
}

.menuContainer {
  display: none;
}

.menuLinkList {
  padding: 0;

  .link {
    display: block;
    padding: 12px 0;
  }
}

.separator {
  display: none;
}

@media screen and (max-width: 768px) {
  .separator {
    display: block;
    width: auto !important;
    margin: 0 24px !important;
  }
  .container {
    height: 80px;
  }

  .languageSelector {
    position: unset !important;
    display: block;
    background-color: $dark-purple-4 !important;
  }

  .verticalSeparator {
    display: none;
  }

  .menuIcon {
    display: block;
  }

  .menuHidden {
    display: none !important;
  }

  .menuContainer {
    display: block;
  }

  .linkList {
    display: block;
    padding: 0;
    position: absolute;
    right: 0;
    left: 0;
    background-color: $dark-purple-3;
    color: white;
    text-align: center;
  }
}
