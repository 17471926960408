@import 'styles/colors';

.container {
  margin-top: 120px;
  min-height: 100vh;
  padding: 150px 24px;
  padding-bottom: 50px;
  text-align: center;

  background: linear-gradient($dark-purple-3, $purple-1, $blue-1);
}

.workingImg {
  margin: 0 auto;
}

.illustrationContainer {
  width: 30%;
}

@media screen and (max-width: 768px) {
  .container {
    height: unset;
    padding-top: 24px;
  }

  .illustrationContainer {
    width: 100%;
  }
}
