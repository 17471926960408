@font-face {
  font-family: 'Crimson Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/static/CrimsonPro-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Crimson Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/static/CrimsonPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Crimson Pro';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/static/CrimsonPro-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Crimson Pro';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/static/CrimsonPro-MediumItalic.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Crimson Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/static/CrimsonPro-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Crimson Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/static/CrimsonPro-Bold.ttf') format('truetype');
}
