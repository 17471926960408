@import 'styles/colors';

.container {
  min-height: 100vh;
  padding: 0 24px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.workingImg {
  width: 400px;
}

.infoContainer {
  opacity: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    padding: 24px;
  }

  .infoContainer {
    opacity: 1;
  }

  .workingImg {
    display: none;
  }
}
