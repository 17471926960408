.container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  padding: 120px 24px;
  padding-bottom: 48px;
}

.pdf {
  width: 100%;
  height: 100%;
}
