@import 'styles/colors';

.container {
  text-align: center;
  padding: 32px 24px;
  z-index: 2;
}

.image {
  border-radius: 100%;
  height: 250px;
}

.text {
  color: white;
  font-size: 1rem;
  margin: 24px auto;
  max-width: 50%;
}

@media screen and (max-width: 768px) {
  .text {
    max-width: unset;
    text-align: justify;
  }
}
