$blue-1: #26c5f3;
$blue-2: #3eabf4;
$blue-3: #5591f5;
$blue-4: #6d77f6;

$purple-1: #855df7;
$purple-2: #9c43f8;
$purple-3: #b429f9;

$dark-purple-1: #702b9e;
$dark-purple-2: #4e2c70;
$dark-purple-3: #2b2d42;
$dark-purple-4: #222433;
