@import 'styles/colors';

.container {
  padding: 64px;
  background: linear-gradient(180deg, $purple-2 0%, $blue-2 100%);
  text-align: center;
}

.linksContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  max-width: 50%;
  margin: 64px auto;
}

.link {
  background: linear-gradient($blue-4, $dark-purple-2);
  border-radius: 16px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  padding: 1.5rem;
  text-align: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  position: relative;
  color: white;
  width: 200px;

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
  }

  .externalLinkIcon {
    position: absolute;
    top: 12px;
    right: 12px;
    fill: white;
  }

  .icon {
    width: 100%;
    height: 100%;
    max-width: 75px;
    max-height: 75px;
    fill: white;
  }

  .descriptionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .description {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  .copyButton {
    position: absolute;
    display: flex;
    gap: 4px;
    bottom: 12px;
    right: 12px;
    transition: all 0.1s ease-in-out;

    &:hover {
      text-decoration: underline;
      transform: scale(1.05);
    }
  }
}

@media screen and (max-width: 768px) {
  .linksContainer {
    max-width: 100%;
  }
}
