@import 'styles/colors';

.container {
  padding: 24px;
  text-align: center;
  background: linear-gradient(180deg, $dark-purple-2 0%, $purple-2 100%);
}

.skillsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  text-align: center;
  flex-wrap: wrap;
  margin: auto;
  max-width: 50%;
  padding: 24px 0;

  > * {
    flex-grow: 1;
    flex-shrink: 0;
  }
}

.skillContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 11rem;
}

.iconContainer {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 16px;
  background: linear-gradient(180deg, $dark-purple-2 0%, $blue-4 100%);
}

.icon {
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .skillsContainer {
    max-width: 100%;
  }
  .skillContainer {
    width: 150px;
  }
}
