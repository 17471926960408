@import 'styles/colors';

.container {
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  background: linear-gradient($dark-purple-3, $dark-purple-2);
}

#bullet_point {
  overflow: visible;
}

text,
.ball {
  visibility: hidden;
}

.illustration {
  width: 100%;
  height: 100%;
  padding: 24px;
  max-width: 50%;
  object-fit: contain;
}

.title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

.list {
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  padding: 24px 0;
  max-width: 80%;
  margin: auto;
}

.listItem {
  border-radius: 16px;
  max-width: 270px;
  width: 270px;
  padding: 24px;
  background: linear-gradient(180deg, $dark-purple-1 0%, $blue-4 100%);
  box-shadow: -4px 10px 17px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.valueTitle {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

.valueDescription {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #ffffff;
  margin: 0;
  text-align: justify;
}

.icon {
  width: 100%;
  height: 100%;
  max-width: 75px;
  max-height: 75px;
  object-fit: contain;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .list {
    max-width: 100%;
    justify-content: center;
  }

  .illustration {
    max-width: 100%;
  }
}
