@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/reset';
@import 'styles/svg_animations.scss';

body {
  margin: 0;
  font-family: -apple-system, 'Crimson Pro', BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $dark-purple-3;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#smooth-content {
  padding-top: 1px;
  margin-top: -1px;
}
